exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-foundations-in-science-tsx": () => import("./../../../src/pages/about-us/foundations-in-science.tsx" /* webpackChunkName: "component---src-pages-about-us-foundations-in-science-tsx" */),
  "component---src-pages-about-us-leadership-team-tsx": () => import("./../../../src/pages/about-us/leadership-team.tsx" /* webpackChunkName: "component---src-pages-about-us-leadership-team-tsx" */),
  "component---src-pages-about-us-who-we-are-tsx": () => import("./../../../src/pages/about-us/who-we-are.tsx" /* webpackChunkName: "component---src-pages-about-us-who-we-are-tsx" */),
  "component---src-pages-accessibility-tsx": () => import("./../../../src/pages/accessibility.tsx" /* webpackChunkName: "component---src-pages-accessibility-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-expanded-access-tsx": () => import("./../../../src/pages/expanded-access.tsx" /* webpackChunkName: "component---src-pages-expanded-access-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-index-tsx": () => import("./../../../src/pages/investors/index.tsx" /* webpackChunkName: "component---src-pages-investors-index-tsx" */),
  "component---src-pages-media-center-events-index-tsx": () => import("./../../../src/pages/media-center/events/index.tsx" /* webpackChunkName: "component---src-pages-media-center-events-index-tsx" */),
  "component---src-pages-media-center-news-[slug]-tsx": () => import("./../../../src/pages/media-center/news/[slug].tsx" /* webpackChunkName: "component---src-pages-media-center-news-[slug]-tsx" */),
  "component---src-pages-media-center-news-index-tsx": () => import("./../../../src/pages/media-center/news/index.tsx" /* webpackChunkName: "component---src-pages-media-center-news-index-tsx" */),
  "component---src-pages-pipeline-index-tsx": () => import("./../../../src/pages/pipeline/index.tsx" /* webpackChunkName: "component---src-pages-pipeline-index-tsx" */),
  "component---src-pages-pricing-disclosures-tsx": () => import("./../../../src/pages/pricing-disclosures.tsx" /* webpackChunkName: "component---src-pages-pricing-disclosures-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-inpefa-tsx": () => import("./../../../src/pages/products/inpefa.tsx" /* webpackChunkName: "component---src-pages-products-inpefa-tsx" */),
  "component---src-pages-site-map-tsx": () => import("./../../../src/pages/site-map.tsx" /* webpackChunkName: "component---src-pages-site-map-tsx" */),
  "component---src-pages-styles-tsx": () => import("./../../../src/pages/styles.tsx" /* webpackChunkName: "component---src-pages-styles-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

