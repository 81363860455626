module.exports = [{
      plugin: require('../node_modules/gatsby-transformer-pdf-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/lexicon-logo-transparent.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"135506b94039012c51ed0b5c067628fe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KVHDSQS","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"()=>(globalThis.consentListeners=[],globalThis.addConsentListener=e=>{globalThis.consentListeners.push(e)},globalThis.onConsentChange=e=>{globalThis.consentListeners.forEach((n=>{n(e)})),document.cookie=\"gtm-consent=\"+JSON.stringify(e)},{})"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
